import { Button, Image, Row, Typography, Flex, Spin } from 'antd'
import moment from 'moment'
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { AppContext } from '../../../hooks/AppContext'
import { PromptType, RenderHistoryItemType } from '../../../types/media_type'
import { LoadingOutlined } from '@ant-design/icons'
import PreviewImagesModal from '../../Img2Img/components/PreviewImagesModal'

type Props = {
  item: RenderHistoryItemType
  isLoraProcessing?: boolean
}

const ImageItem: React.FC<Props> = (props) => {
  const { item, isLoraProcessing } = props
  const { isMobile } = useContext(AppContext)
  const parentRef: any = useRef()
  const [heightParent, setHeightParent] = useState<number>(0)
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false)
  const [imagesPreview, setImagesPreview] = useState<{ url: string }[]>([])

  const fontMobileStyle = {
    fontSize: 12,
    marginRight: 5,
    height: '100%'
  }

  const getSystemPromptTitle = (value: number) => {
    switch (value) {
      case 1:
        return 'スタイル: 写真の要素をインスパイアー\n\n'

      case 2:
        return 'スタイル: 写真のプロダクトの要素を落とし込む\n\n'

      case 3:
        return 'スタイル: アップロードされた画像に合うブランドプロフィール用の商品を作るプロンプト\n\n'

      case 4:
        return 'スタイル: 生地の写真をアップしたらプロフィールに合致する画像を作成する\n\n'

      default:
        break
    }
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      setHeightParent(parentRef.current?.offsetHeight)
    }

    const height = parentRef?.current?.offsetHeight
    setHeightParent(height)

    setTimeout(() => {
      const newHeight = parentRef?.current?.offsetHeight
      if (height !== newHeight) {
        setHeightParent(newHeight)
      }
    }, 2000)
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <Row className='min-h-9 items-center text-center w-full h-[90px]' style={{ overflow: 'hidden' }}>
        <Flex className='flex-[0.5]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          <Typography style={isMobile ? fontMobileStyle : { textAlign: 'left' }}>
            {item.user?.name ?? item?.user?.email}
          </Typography>
        </Flex>
        <Flex className='flex-[0.5]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          {isLoraProcessing ? (
            <Spin spinning={true} indicator={<LoadingOutlined style={{ fontSize: 24 }} />}>
              <div style={{ height: 90, aspectRatio: 1 / 1, backgroundColor: '#D9D9D9', marginBottom: 3 }} />
            </Spin>
          ) : (
            <>
              {item?.medias?.length > 0 ? (
                <Flex className=''>
                  <Image
                    onClick={() => {
                      setImagesPreview(item?.medias ?? [])
                      setIsShowPreview(true)
                    }}
                    preview={false}
                    src={item?.medias[0]?.url}
                    height={60}
                    style={{ width: 'auto' }}
                  />
                </Flex>
              ) : (
                <div style={{ width: 60, aspectRatio: 1 }} />
              )}
            </>
          )}
        </Flex>
        <Flex className='flex-[0.5]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          <>
            {Object.values(item?.original_media ?? []).length > 0 ? (
              <Flex className=''>
                <Image
                  onClick={() => {
                    setImagesPreview((item?.original_media ?? []).map((url) => ({ url })))
                    setIsShowPreview(true)
                  }}
                  preview={false}
                  src={item?.original_media![0]}
                  height={60}
                  style={{ width: 60, objectFit: 'cover' }}
                />
              </Flex>
            ) : (
              <div style={{ width: 60, aspectRatio: 1 }} />
            )}
          </>
        </Flex>
        <Flex className='flex-[0.6]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          {isLoraProcessing ? (
            <img src={require('../../../assets/loading.gif')} width={100} />
          ) : (
            <Typography style={isMobile ? fontMobileStyle : { textAlign: 'left' }}>
              {moment(item?.updated_at).format('YYYY/MM/DD HH:mm')}
            </Typography>
          )}
        </Flex>
        <Flex
          className='flex-[1]'
          style={{
            // alignItems: 'flex-start',
            marginRight: 16,
            // placeItems: 'center',
            height: '50%',
            marginTop: 8,
            overflow: 'hidden'
          }}
        >
          <Typography style={isMobile ? fontMobileStyle : { textAlign: 'left' }}>
            {item?.description
              ? item?.description
              : item?.profile_jp
              ? item?.profile_jp
              : item?.checkpoint_profile?.profile_jp}
          </Typography>
        </Flex>
        <Flex className='flex-[0.7]' style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16 }}>
          <Typography style={isMobile ? fontMobileStyle : { textAlign: 'left' }}>
            {item.generate_type === 3 || item.generate_type === 0
              ? 'テキストで画像生成'
              : item.generate_type === 7
              ? '生地変更'
              : '画像から画像生成'}
          </Typography>
        </Flex>
        <Flex
          className='flex-[1]'
          style={{
            // alignItems: 'flex-start',
            marginRight: 16,
            // placeItems: 'center',
            height: '50%',
            marginTop: 8,
            overflow: 'hidden'
          }}
        >
          <Typography style={isMobile ? fontMobileStyle : { textAlign: 'left' }} className=''>
            {item?.category ?? item?.checkpoint}
          </Typography>
        </Flex>
        <Flex
          className='flex-[1.5]'
          style={{ alignItems: 'center', justifyContent: 'flex-start', marginRight: 16, marginLeft: 8 }}
        >
          {isLoraProcessing ? (
            <div className='flex-[1.5]'>
              <img src={require('../../../assets/loading.gif')} width={100} />
            </div>
          ) : (
            <Typography
              style={isMobile ? fontMobileStyle : { height: 60, overflowY: 'auto' }}
              className='scroll-hover flex-[1.5] whitespace-pre-line text-start pt-3 mb-3'
            >
              {getSystemPromptTitle(item.system_prompt_type ?? 0)}
              {item?.prompts?.map(
                (item: PromptType, index: number) =>
                  `プロンプト${index + 1}: ${item?.prompt ? item?.prompt : item?.prompt_en}\n\n`
              )}
            </Typography>
          )}
        </Flex>
        <Flex className='flex-[0.5]' style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Typography.Paragraph style={isMobile ? fontMobileStyle : { textAlign: 'left' }} className=''>
            {Number(item?.time_second / 60).toFixed(0)}分消費
          </Typography.Paragraph>
        </Flex>
      </Row>
      <Row className='h-[1px] bg-[#5AAAFF]' />
      <PreviewImagesModal
        imageList={imagesPreview ?? []}
        indexPreview={0}
        isPreview={isShowPreview}
        setIsPreview={setIsShowPreview}
      />
    </>
  )
}

export default ImageItem
